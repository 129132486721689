import React, { useEffect, useState } from "react";
import "../Modals/Confirmation.css";
import rarityData from "../../assets/rarity.json";

const Modal = ({ item, onClose, traits }) => {
  // console.log(rarityData);
  // Extracting serial number from item.body
  const match = item.body.trim().match(/^\s*(\d+)/); // Updated regex to handle leading spaces
  const serialNumber = match ? match[1].padStart(3, "0") : null;
  const rarityInfo = serialNumber
    ? rarityData.find((rarity) => rarity.SN === serialNumber)
    : null;

  // Combine rarityInfo with traits if available
  let combinedTraits = traits.slice(); // Clone the traits array
  if (rarityInfo) {
    combinedTraits.push({
      t: "Rarity Rank",
      v: rarityInfo.Rank,
    });
  }

  return (
    <div
      className="fixed top-0 left-0 right-0 bottom-0 bg-black bg-opacity-60 flex items-center justify-center modalBackground"
      onClick={onClose}
    >
      <div
        className="bg-white rounded-lg shadow-lg relative p-4 modal-container"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-center text-lg">{item.body}</h2>

        <div className="flex justify-center mb-4">
          <img
            className="w-72 h-72 md:w-96 md:h-96 object-cover rounded"
            src={item.imageURLs}
            alt={item.body}
          />
        </div>

        {/* {console.log("traits", traits)} */}

        {traits.length >= 1 ? <></> : ""}

        {traits
          ? traits.map((trait, index) => {
              // Log the trait
              // console.log("checking");
              // console.log(`Trait #${index}:`, trait.traits);

              // Map over the trait.traits array to display each t and v

              return (
                <div key={index}>
                  {trait.traits.find(({ t }) => t === "Name") ? (
                    <div className="flex justify-between items-center mb-2">
                      <div className="font-medium mr-2">Name:</div>
                      <div className="text-sm text-gray-800">
                        {item.body.match(/[a-zA-Z]+/)}
                      </div>
                    </div>
                  ) : (
                    <div className="flex justify-between items-center mb-2">
                      <div className="font-medium mr-2">Name:</div>
                      <div className="text-sm text-gray-800">
                        {item.body.match(/[a-zA-Z]+/)}
                      </div>
                    </div>
                  )}

                  {combinedTraits.slice(1).map((trait, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center mb-2"
                    >
                      <div className="font-medium mr-2">Rank:</div>
                      <div className="text-sm text-gray-800">{trait.v}</div>
                    </div>
                  ))}

                  {trait.traits
                    .filter(({ t }) => t !== "Name") // Exclude "Name" trait
                    .map((innerTrait, innerIndex) => (
                      <div
                        key={innerIndex}
                        className={`flex justify-between items-center mb-2 ${
                          innerTrait.t === "Category" ? "hidden" : ""
                        }`}
                      >
                        <div className="font-medium mr-2">{innerTrait.t}:</div>
                        <div className="text-sm text-gray-800">
                          {innerTrait.v}
                        </div>
                      </div>
                    ))}
                </div>
              );
            })
          : ""}

        {/* Render your traits here */}
        <button
          className="bg-black text-white w-full py-2 rounded"
          onClick={onClose}
        >
          OK
        </button>
      </div>
    </div>
  );
};

const Genesis = ({ data, traits }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedTrait, setSelectedTraits] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [finalData, setFinalData] = useState();
  const handleCardClick = (item) => {
    setShowModal(true);
    setSelectedItem(item);
    setSelectedTraits(traits.filter((i) => i.id === item.postHashHex));
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedItem(null);
  };

  const mergeArrByCommonId = (arr1, arr2) => {
    return arr1.map((item) => {
      const matchingItem = arr2.find((i2) => i2.id === item.postHashHex);

      if (matchingItem) {
        return {
          ...item,
          ...arr2.find((i2) => i2.id === item.postHashHex),
        };
      } else {
        return { ...item };
      }
    });
  };

  useEffect(() => {
    setFinalData(mergeArrByCommonId(data, traits));
    console.log("finalData", finalData);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="grid grid-cols-1 mt-4 sm:grid-cols-2 md:grid-cols-4">
      {data ? (
        data.map((item, index) => {
          return (
            <>
              <div
                className="border w-full h-full"
                key={index}
                onClick={() => handleCardClick(item)}
              >
                <div className="m-2">
                  <div className="w-full aspect-square">
                    <img
                      src={item.imageURLs[0]}
                      alt="logo"
                      className="image-inner"
                      loading="lazy"
                    />
                  </div>
                  <div className="flex justify-between items-center pt-2">
                    <div>
                      <div className="owner">
                        <div className="owner-text">{item.body}</div>
                      </div>
                    </div>
                    <div className="price">
                      <button
                        className={`buy-now ${
                          Number(item.numNFTCopiesForSale) <= 0
                            ? "bg-gray-500 cursor-not-allowed"
                            : "bg-[#03455f]"
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          const baseUrl = "https://www.diamondapp.com/nft/";
                          const hashHex = item.postHashHex;
                          const newUrl = baseUrl + hashHex;
                          window.open(newUrl, "_blank");
                        }}
                        disabled={Number(item.numNFTCopiesForSale) <= 0}
                      >
                        Buy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })
      ) : (
        <>
          <div className="border w-96 h-96 bg-red-50 ">
            <div className="m-2">
              <div className="w-full aspect-square bg-red-300"></div>
              <div className="flex justify-between items-center pt-2">
                <div>
                  <div className="owner">
                    <div className="owner-text">Loading...</div>
                  </div>
                  <div className="price">
                    <button
                      className={`buy-now 
                           bg-gray-500 cursor-not-allowed`}
                    >
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {showModal && selectedItem && (
        <Modal
          item={selectedItem}
          onClose={handleCloseModal}
          traits={selectedTrait}
        />
      )}
    </div>
  );
};

export default Genesis;
