import React from "react";
import { TailSpin } from "react-loader-spinner";

const breakNumber = (maxNumber) => {
  let data = [];
  for (let i = 0; i < maxNumber; i++) {
    data.push(i);
  }
  return data;
};

const NoData = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4">
      {breakNumber(20).map((item, index) => {
        return (
          <>
            <div className="border w-full h-full" key={index}>
              <div className="m-2">
                <div className="flex w-full aspect-square bg-gray-400 justify-center items-center">
                  <div>
                    <TailSpin
                      height="80"
                      width="80"
                      color="#ffffff"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                </div>
                <div className="flex justify-between items-center pt-2">
                  <div>{/* Placeholder for potential content */}</div>
                  <div className="price">
                    <button
                      className={`buy-now bg-gray-500 cursor-not-allowed`}
                      disabled={true}
                    >
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

export default NoData;
