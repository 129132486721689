import React, { useRef, useState } from "react";
import { CgCloseO } from "react-icons/cg";
import { configure } from 'deso-protocol';
import { identity, getUsersStateless } from "deso-protocol";
import { Loader } from 'semantic-ui-react'
import 'semantic-ui-css/semantic.min.css';
import "./Confirmation.css";
import axios from "axios";

configure({
  spendingLimitOptions: {
    GlobalDESOLimit: 2.1 * 1e9,
    "NFTOperationLimitMap": {
      "": {
        0: {
          "nft_bid": 1,
        },
      }
    },
  }
})
const Confirmation = ({
  handleModal,
  setNftModal,
  NotificationManager,
  setNftData
}) => {
  const [isLoading, setIsLoading] = useState(false);
  // const deso = new DeSoNetwork()
  const closeRef = useRef(null);

  async function handleTx() {
    setIsLoading(true)
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
      console.log("Request time out!")
    }, 10000);
    try {
      const data = await identity.login();
      console.log(data)
      console.log(data.derivedPublicKeyBase58Check)
      const { publicKeyBase58Check, derivedPublicKeyBase58Check } = data;
      const user = await getUsersStateless({ "PublicKeysBase58Check": [publicKeyBase58Check] });
      const BalanceNanos = user.UserList[0].BalanceNanos > 2e9
      if (BalanceNanos) {
        setNftModal(true)
        // Take the derived seed hex from the localStorage
        const activeKey = localStorage.getItem('desoActivePublicKey');
        const obj = localStorage.getItem('desoIdentityUsers');
        const jsonObject = JSON.parse(obj);
        const derivedSeedHex = jsonObject[activeKey].primaryDerivedKey.derivedSeedHex;
        // const url = "https://whales-backend.azurewebsites.net/api/nftransfer/mintrandom";
        // const url = "http://localhost:4000/api/nftransfer/mintrandom";
        const url = "https://server.dewhales.art/api/nftransfer/mintrandom";
        const entry = {
          "DerivedPublicKeyBase58Check": derivedPublicKeyBase58Check,
          "receiverAddress": publicKeyBase58Check,
          "DerivedSeedHex": derivedSeedHex
        };
        const response = await axios.put(url, entry);
        const nftData = response.data;
        setNftData(prevState => ({
          ...prevState,
          nftName: nftData.SN,
          Iname: nftData.Iname,
          bgColor: nftData.bgColor,
          accessories: nftData.accessories,
          imgLink: nftData.oImageAddress

        }));
        console.log(response)
        clearTimeout(loadingTimeout);
        setIsLoading(false);
      } else {
        console.log("Not Enough Balance")
        NotificationManager.error('Not Enough Balance On Your Wallet.', 'Sorry!', 3000);
      }
    } catch (error) {
      console.error("Error in handleTx:", error);
      clearTimeout(loadingTimeout);
      setIsLoading(false)
    }
    setIsLoading(false);
  }

  return (
    <>
      <div className="modalBackground">
        <div className="modalContainer">
          <div className="titleCloseBtn">
            <button
              onClick={() => {
                handleModal(false);
              }}
            >
              <CgCloseO size={35} style={{ color: "#032854" }} />
            </button>
          </div>
          <div className="titleconfirmation">
            <h1>Are you sure to continue?</h1>
          </div>
          <div className="body">
            <p>
              Please allow access to your account to mint NFT. We are requesting
              you to grant<b> 2 $DESO</b> for NFT to mint.
            </p>
          </div>
          <div className="footer">
            <button
              onClick={() => {
                handleModal(false);
              }}
              id="cancelBtn"
              ref={closeRef}
            >
              Cancel
            </button>
            <div>
              <button disabled={isLoading} onClick={handleTx}>
                {isLoading ? <Loader inverted active inline='centered' size="tiny" /> : <div>ALLOW</div>}
              </button>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Confirmation;
