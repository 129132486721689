import React, { useState } from 'react'
import { CgMenuRound } from "react-icons/cg";
import { CgCloseO } from "react-icons/cg";
import { Link, useLocation } from "react-router-dom";
import { SidebarData, footerdata } from './SidebarData';
import './Navbar.css';
import { IconContext } from 'react-icons'
import img from '../assets/navbarlogo.png';
import 'react-toastify/dist/ReactToastify.css';

function Navbar({ setstate }) {
  const [sidebar, setSidebar] = useState(false);
  // eslint-disable-next-line
  const location = useLocation();
  const showSidebar = () => { setSidebar(!sidebar); console.log(sidebar) }
  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <div className="navbar">

          <div className='menu-bars'>
            <CgMenuRound className='openicon' onClick={showSidebar} />
          </div>
          <img src={img} alt='img' className='navimg' />

        </div>

        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>

          <ul className='nav-menu-items' onClick={showSidebar}>
            <li className="navbar-toggle">
              <Link to={location.pathname} className='menu-close'>
                <CgCloseO />
              </Link>
            </li>

            <div className='gap'></div>

            {SidebarData.map((item, index) => {
              return (
                <div className='list-items'>
                  <li key={index} className={item.className}>
                    <Link to={item.path}>
                      {item.icon}&nbsp;&nbsp;
                      <span className={`title ${location.pathname === item.path ? 'side-text-active' : ''}`}>{item.title}</span>
                    </Link>
                  </li>
                </div>

              )
            })}
            <div className='foot-items'>
              {footerdata.map((item, index) => {
                return (
                  <li key={index} className={item.className} style={{ listStyleType: 'none' }}>
                    <a href={item.href} target='_blank' rel="noreferrer">
                      {item.icon}&nbsp;&nbsp;

                    </a>
                  </li>
                )
              })}
            </div>
          </ul>

        </nav>
      </IconContext.Provider>
    </>
  )
}

export default Navbar