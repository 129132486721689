import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
// import { parse } from 'superagent';
// import Card from './Card';
import Genesis from "./Genesis";
import "./style.css";
import NoData from "./NoData";

const Index = () => {
  const [traits, setTraits] = useState([]);
  // if datatype=0 you will get the all created nfts
  // if datatype=1 you will get all the for sale nfts
  // if datatype=4 you will get all the secondary nfts

  const [image0, setImage0] = useState([]);
  const [image1, setImage1] = useState([]);
  const [image4, setImage4] = useState([]);
  const [genesisData, setGenesisData] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [showGenesis, setShowGenesis] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [combinedNonGenesisData, setCombinedNonGenesisData] = useState([]);
  const activeButtonStyle = "bg-black text-white";
  const inactiveButtonStyle = "bg-white text-black";

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(
          "https://desodata.azureedge.net/creator-extra-data/BC1YLhn4Z8Xn1R3XYZna9FYWUkLVkzxjmoJ76kbvuU2n4J7AsHxcJaM.json"
        )
        .then((res) => {
          // console.log(res.data);
          setTraits(res.data.nftswithdata);
        })
        .catch((err) => console.log(err));

      await axios
        .get(
          "https://nftzapi.azurewebsites.net/api/creator/nfts?datatype=1&username=DeWhales"
        )
        .then((res) => {
          // console.log(res.data);
          setImage1(res.data);
        })
        .catch((err) => console.log(err));
      await axios
        .get(
          "https://nftzapi.azurewebsites.net/api/creator/nfts?datatype=0&username=DeWhales"
        )
        .then((res) => {
          setImage0(res.data);
        })
        .catch((err) => console.log(err));
      await axios
        .get(
          "https://nftzapi.azurewebsites.net/api/creator/nfts?datatype=4&username=DeWhales"
        )
        .then((res) => {
          // console.log(res.data);
          setImage4(res.data);
        })
        .catch((err) => console.log(err));
    };

    fetchData();
  }, []);

  const isGenesis = useCallback((Arr, isGenesis) => {
    const temp = [];
    Arr.forEach((item) => {
      const name = item.body.trim().split(" ")[0];
      if (isGenesis) {
        if (/^00[1-9]$|^0\d{2}$|^[1-4]\d{2}$/.test(name)) {
          temp.push(item); // Matches 001-009, 010-099, and 100-444 for non-Genesis
        }
      } else {
        if (/^0[1-9]$|^[1-9]\d$/.test(name)) {
          temp.push(item); // Matches 01-09 and 10-99 for Genesis
        }
      }
    });
    return temp;
  }, []);

  const sortingArr = useCallback((pastArr, arr) => {
    const name = (name) => {
      let temp = name.trim().split(" ")[0];
      return parseInt(temp);
    };

    const mergeArr = [...pastArr, ...arr];

    mergeArr.sort((a, b) => {
      return name(a.body) - name(b.body);
    });

    const finalNonDup = mergeArr.filter((item, index) => {
      const firstIndex = mergeArr.findIndex(
        (item2) => item2.body === item.body
      );
      return firstIndex === index;
    });

    return finalNonDup;
  }, []);

  useEffect(() => {
    if (
      image0.length < 1 ||
      image1.length < 1 ||
      image4.length < 1 ||
      traits.length < 1
    )
      return;
    // console.log([...image0, ...image1, ...image4].length, "allData");

    const gen0 = isGenesis(image0, true);
    const gen1 = isGenesis(image1, true);
    const gen4 = isGenesis(image4, true);

    const oth0 = isGenesis(image0, false);
    const oth1 = isGenesis(image1, false);
    const oth4 = isGenesis(image4, false);

    const temp = sortingArr(gen0, gen1);
    const final = sortingArr(temp, gen4);
    setGenesisData(final);

    const temp2 = sortingArr(oth0, oth1);
    const final2 = sortingArr(temp2, oth4);
    setOtherData(final2);

    const temp3 = sortingArr(final, final2);
    setAllData(temp3);
    // console.log(allData.length, "allData");
    // console.log(traits.length, "traits");
    const nonGenesisData = isGenesis(allData, true);
    if (combinedNonGenesisData.length > 0) return;
    let combinedData = [];
    for (let i = 0; i < nonGenesisData.length; i++) {
      let data = nonGenesisData[i];
      let matchedTrait = traits.find((trait) => data.postHashHex === trait.id);
      if (matchedTrait) {
        combinedData.push({ ...data, ...matchedTrait });
      } else {
        combinedData.push(data);
      }
    }
    setCombinedNonGenesisData(combinedData);
    // console.log(combinedData, "combinedData");
    // eslint-disable-next-line
  }, [image0, image1, image4, isGenesis, sortingArr, combinedNonGenesisData]);

  useEffect(() => {
    const temp = allData.filter((item) => {
      return item.body.toLowerCase().includes(search.toLowerCase());
    });
    setFilteredData(temp);
  }, [search, allData]);

  return (
    <div className="">
      <h1 className="title">Gallery</h1>

      <div>
        <div className="w-screen">
          <div className="flex w-6/12 mx-auto">
            <div className="border w-full rounded-md overflow-hidden  mx-auto border-slate-400">
              <input
                type="text"
                className="py-2 focus:outline-none px-5 w-full h-full"
                placeholder="100 Alex"
                onChange={(e) => {
                  // console.log(e.target.value);
                  setSearch(e.target.value);
                }}
              />
            </div>
            <input
              type="button"
              value="Search"
              className="bg-[#03455f] text-white px-5 py-2 rounded-md ml-2"
            />
          </div>
        </div>
        {/* button */}
        {search === "" ? (
          <div className="flex justify-center items-center mt-5">
            <button
              className={`px-4 py-2 rounded-md custom-button ${
                showGenesis ? activeButtonStyle : inactiveButtonStyle
              }`}
              onClick={() => setShowGenesis(true)}
            >
              Genesis
            </button>
            <button
              className={`px-4 py-2 rounded-md custom-button ml-2 ${
                !showGenesis ? activeButtonStyle : inactiveButtonStyle
              }`}
              onClick={() => setShowGenesis(false)}
            >
              Semi Generative
            </button>
          </div>
        ) : null}
      </div>
      <div>
        {/* <NoData /> */}
        {allData.length < 1 ? (
          <NoData />
        ) : search === "" ? (
          showGenesis ? (
            <Genesis data={otherData} traits={combinedNonGenesisData} />
          ) : (
            <Genesis data={genesisData} traits={combinedNonGenesisData} />
          )
        ) : (
          <Genesis data={filteredData} traits={combinedNonGenesisData} />
        )}
      </div>
    </div>
  );
};

export default Index;
