import '../pages/Home.css';
import React, { useState } from 'react'
import img from '../assets/landingimg.png'
import '../assets/fonts/pineapple.ttf'
import Confirmation from './Modals/Confirmation'
import { Nftmodal } from './Modals/Nftmodal';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

export default function Home({ valid, ukey }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [nftModal, setNftModal] = useState(false)
  const [data, setData] = useState({ hex: "", validity: false, mint: false });
  const [nftData, setNftData] = useState({ nftName: 0, imgLink: "hnsd29SFVxHeY-RI7-dcb56q8w7hSYVv0nNE88ScgO0", Iname: "none", bgColor: "none", accessories: "none" })
  const handleModal = (data) => {
    setModalOpen(data)
  }
  const handleData = (hex, validity, mint) => {
    setData({ hex, validity, mint });
  }
  return (
    <>

      <div className='home'>
        {modalOpen && <Confirmation handleModal={handleModal} setNftData={setNftData} handleData={handleData} ukey={ukey} NotificationManager={NotificationManager} nftModal={nftModal} setNftModal={setNftModal} />}
        {data.mint && <Confirmation handleModal={handleModal} setNftData={setNftData} handleData={handleData} ukey={ukey} NotificationManager={NotificationManager} nftModal={nftModal} setNftModal={setNftModal} />}
        <div className='mobile'></div>
        <div className='hometext'>
          <h1>DeWhales is the semi-generative NFT project
            of 444 Whales deployed on  DeSo Blockchain.
          </h1>

          <div className="mint_btn"><button type="button" onClick={() => {
            setModalOpen(true);
            localStorage.removeItem('desoIdentityUsers');
            localStorage.removeItem('desoActivePublicKey');
          }} >MINT</button></div>
          {nftModal && <Nftmodal handleData={handleData} nftData={nftData} handleModal={handleModal} nftModal={nftModal} setNftModal={setNftModal} data={data} ukey={ukey} />}

          <div className='space'></div>
        </div>
        <img className='roadmap' src={img} alt='roadmap' />
      </div>
      <NotificationContainer />
    </>
  )
}
